.no-pointer {
  pointer-events: none;
}

.App-header {
  width: 100vw;
  position: fixed;
  top: 0;
  background-color: #212121; /* rgba(33, 33, 33, 0.6) */
  height: 85vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.routes {
  position: fixed;
  right: 25px;
  height: 85px;
  display: flex;
  flex-direction: row;
  padding: auto 0;
}

.route {
  font-weight: bold;
  color: rgba(76, 184, 196, 1);
  margin: auto 20px auto 0;
  z-index: 1000;
}

.route:hover {
  color: #3cd3ad;
  transform: scale(1.05);
}
