body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page {
  text-align: center;
  background-color: #212121;
  width: 100vw;
}

/* remove scrollbar */
body {
  overflow-x: hidden;
}

/* text */
p,
a {
  color: #fafafa;
  font-family: Archivo;
  font-size: 1rem;
}

p.button-text {
  color: #212121;
}

a:hover {
  color: #3cd3ad;
}

a.onhover-color-white:hover {
  color: #fafafa;
}

h1 {
  font-size: 2.5rem;
  font-family: Archivo Black;
  color: #4cb8c4;
}

h2 {
  font-size: 2rem;
  font-family: Archivo Black;
  color: #4cb8c4;
}

h3 {
  font-size: 1.5rem;
  font-family: Archivo Black;
  color: #4cb8c4;
}

/* elements */

header {
  z-index: 999;
}

input {
  border-color: white;

  & :focus {
    border-color: #4cb8c4;
  }
}

.button {
  padding: 5px 10px;
  border-radius: 8px;
  background-color: #4cb8c4;
}

.button:hover {
  background-color: #3cd3ad;
}

.gradient-block .button {
  background-color: #212121;
}

.gradient-block .button:hover {
  background-color: rgba(33, 33, 33, 0.8);
}

.gradient-block .button-text {
  color: #4cb8c4;
}

/* simple */

.bold {
  font-weight: bold;
}

.white {
  color: #fafafa;
}

.black {
  color: #212121;
}

.spacer {
  height: 7vh;
}

.spacer-s {
  height: 20px;
}

/* blocks */

.container {
  max-width: 860px;
  width: 100%;
}

.opaque {
  background: rgba(250, 250, 250, 0.3);
  padding: 30px;
  border-radius: 30px;
}

.black-block {
  width: 100vw;
  padding: 5vh 5vh 5vh 5vh;
  display: flex;
  justify-content: center;
}

.black-block .opaque {
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0px 32px rgba(60, 211, 173, 0.3);
}

.black-block * {
  color: #4cb8c4;
}

.black-light-block {
  background-color: #282828;
  width: 100vw;
  padding: 5vh;
  display: flex;
  justify-content: center;
}

.gradient-block {
  background: linear-gradient(to right, #4cb8c4, #3cd3ad);
  width: 100vw;
  padding: 5vh 5vh 5vh 5vh;
  display: flex;
  justify-content: center;
}

/* text in blocks */

.gradient-block h1 {
  color: #212121;
}
.gradient-block h2 {
  color: #212121;
}
.gradient-block h3 {
  color: #212121;
}
.gradient-block p {
  color: #212121;
}
.gradient-block a:hover {
  color: #fafafa;
}

/* Media Queries */

/* Tablet */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  p,
  a {
    font-size: 1.2rem;
  }

  h1 {
    font-size: 2.7rem;
  }

  h2 {
    font-size: 2.2rem;
  }

  h3 {
    font-size: 1.7rem;
  }
}

/* Desktop */
@media screen and (min-width: 1024px) {
  p,
  a {
    font-size: 1.5rem;
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2rem;
  }
}
