.fab {
  position: fixed;
  right: 45px;
  top: 45px;
  height: 80px;
  width: 80px;
  padding: 10px;
  background-color: #212121;
  border-radius: 40px;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(76, 184, 196, 0.1),
    0 4px 8px rgba(76, 184, 196, 0.1); /* Material Design box shadow */
  transition: box-shadow 0.3s ease-in-out;
}

.fab:hover {
  box-shadow: 0 4px 8px rgba(76, 184, 196, 0.2),
    0 8px 16px rgba(76, 184, 196, 0.2); /* Adjust the shadow on hover */
}

.translated > a {
  right: -400px;
}

.dropdown-item {
  font-family: Archivo;
  font-size: x-large;
  font-weight: bold;
  background-color: #212121;
  padding: 2px 14px;
  border-radius: 50px;
  color: #4cb8c4;
  position: fixed;
  right: 45px;
  z-index: 1000;
  /* box-shadow: 0 2px 4px rgba(76, 184, 196, 0.1), */
  /* 0 4px 8px rgba(76, 184, 196, 0.1); Material Design box shadow */
  /* transition: box-shadow 0.3s ease-in-out; */
  transition: right 0.3s ease-in-out;
}

.dropdown-item:hover {
  /* box-shadow: 0 4px 8px rgba(76, 184, 196, 0.2), */
  /* 0 8px 16px rgba(76, 184, 196, 0.2); Adjust the shadow on hover */
}
